<template>
  <div class="container show-all-tnc">
      <div class="row ml-1">
        <img src="./../assets/care_dokter_logo.png" alt="logo-care-dokter" style="height: 65px;">
      </div>
      <h1>Syarat dan Ketentuan</h1>
      <div class="content-tnc text-left">
        <h4>RUANG LINGKUP</h4>
        <p>Anda dapat menggunakan beberapa fitur tertentu pada Platform tanpa melakukan pendaftaran terlebih dahulu
            Anda dapat menggunakan seluruh fitur pada Platform dengan terlebih dahulu melakukan pendaftaran yang disertai pemberian informasi data pribadi Anda yang dapat dipertanggung jawabkan secara hukum sebagaimana diminta pada Platform (“Data Pribadi”).</p>

        <h4>KONDISI PENGGUNAAN</h4>
        <p>
            Jika Anda ingin melakukan reservasi untuk jadwal dokter dokter, pastikan Anda memiliki akun dan sudah login ke platform. Anda dapat melakukan reservasi untuk konsultasi secara online dengan dokter yang ada pada CARE DOKTER. Anda dapat melakukan pembatalan booking konsultasi maksimal 60 (enam puluh) menit sebelum jadwal untuk konsultasi dimulai. Proses pengembalian dana akibat pembatalan booking konsultasi akan dilakukan sesuai dengan prosedur dan ketentuan yang berlaku.
            Jika Anda tidak hadir pada jadwal booking yang telah Anda pilih, maka Anda menyetujui bahwa dana yang telah Anda bayarkan tidak dapat dikembalikan.
        </p>

        <h4>HAK CIPTA</h4>
        <p>
            Platform ini dimiliki dan dioperasikan oleh CARE DOKTER. Platform ini tidak boleh disalin, direproduksi, dimodifikasi atau diterbitkan ulang tanpa izin tertulis sebelumnya dari CARE DOKTER.
        </p>

        <h4>DISCLAIMER</h4>
        <ol>
            <li>Pastikan Anda membaca dan memahami semua syarat dan ketentuan yang berlaku. 
            CARE DOKTER tidak bertanggung jawab atas masalah yang ditimbulkan jika Anda memberikan informasi nama pengguna dan kata sandi Anda kepada orang lain. Biaya yang berlaku untuk telekonsultasi dapat dilihat pada Platform, dan biaya tersebut dapat diperbaharui dari waktu ke waktu tanpa pemberitahuan terlebih dahulu.</li>
            <li>Platform ini menjamin tejaganya kerahasiaan yang anda berikan untuk transaksi yang akan dilakukan.</li>
        </ol>

        <h4>DAFTAR</h4>
        <p>
            Anda akan mendapatkan manfaat seperti lihat/cari dokter atau jadwal dan slot jadwal dokter yang ada pada MANDAYA HOSPITAL GROUP dengan melakukan pendaftaran pada platform. Anda akan diminta untuk memberikan informasi (Data Diri) yang akurat dan terkini melalui formulir pendaftaran di Platform ini. Anda mengetahui dan menyetujui bahwa Kami mengumpulkan informasi pribadi yang Anda berikan saat Anda membuat akun dan profil, maupun pada saat menggunakan fitur-fitur yang terdapat dalam Platform. Anda sepenuhnya bertanggung jawab untuk menjaga kerahasiaan nama pengguna dan kata sandi Anda untuk mengakses Platform ini, serta aktifitas apapun yang terjadi dengan nama pengguna atau kata sandi Anda. Anda tidak akan menyalahgunakan atau membagikan nama pengguna dan kata sandi Anda kepada orang lain.
        </p>

        <h4>PEMBAYARAN</h4>
        <p>
            Pembayaran saat ini baru tersedia untuk Telekonsultasi, Anda dapat menggunakan berbagai metode pembayaran yang tersedia pada Platform. Batas akhir pembayaran sudah ditentukan maksimal 1X12 jam.
        </p>

        <h4>PENGEMBALIAN DANA</h4>
        <p>
            Jika Anda melakukan pembatalan konsultasi (maksimal 60 (enam puluh) menit sebelumnya) atau konsultasi dibatalkan oleh pihak rumah sakit, maka Anda bisa mengajukan untuk pengembalian dana. Pengembalian dana otomatis (auto refund) hanya bisa dilakukan untuk pembayaran via credit card atau gopay. Anda memahami dan menyetujui bahwa batas waktu pengajuan keluhan mengenai transaksi adalah maksimal 7 (tujuh) hari kalender setelah transaksi selesai.
        </p>

        <h4>PERTANYAAN DAN MASUKAN</h4>
        <p>
            Kami menyambut baik pertanyaan, komentar, dan kekhawatiran Anda tentang privasi atau informasi apa pun yang dikumpulkan dari Anda atau tentang Anda. Harap kirimkan setiap dan semua umpan balik yang berkaitan dengan privasi, atau masalah lainnya melalui email ke info@caredokter.com.
        </p>

      </div>
      <button
        @click="$router.go(-1)"
        type="button"
        class="btn btn-primary"
      >Kembali</button>
  </div>
</template>

<script>
export default {
    name: "page-tnc",

}
</script>

<style>

</style>